import { useTranslation } from "react-i18next";

import MenuItem from "../../components/main-menu/menuItem";
import PipelineList from "../../components/main-menu/pipelineList";
import useWhiteLabel from "../../hooks/useWhiteLabel";
import ToolsMenu from "../main-menu/toolsMenu";
import UserAvatar from "../main-menu/userAvatar";
import Loading from "../loading";
import usePinnedItems from "../../hooks/usePinnedItems";

const MainMenu = () => {
    const { t } = useTranslation();
    const { logo192 } = useWhiteLabel();
    const { pinnedItemsArray, loading } = usePinnedItems();

    const iconMap = {
        'settings': {
            "icon": "ri-settings",
            "title": t("Configurações"),
            "to": "/settings"
        },
        'batch-actions': {
            "icon": "ri-list-check-3",
            "title": t("Ações em lote"),
            "to": "/contacts/batch-actions"
        },
        'import-contacts': {
            "icon": "ri-upload",
            "title": t("Importar contatos"),
            "to": "/contacts/import"
        },
        'referral': {
            "icon": "ri-gift",
            "title": t("Programa de indicações"),
            "to": "/referral"
        },
        'email-verification': {
            "icon": "ri-mail-check",
            "title": t("Verificar email"),
            "to": "/email-verification"
        },
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="main-menu">
            <div className="logo">
                <img src={logo192} alt="" />
            </div>
            <div className="menu-itens">
                <MenuItem to="/chat" icon="ri-chat-3" title={t("Chat")} />
                <MenuItem to="/metrics" icon="ri-line-chart" title={t("Métricas")} />
                <MenuItem to="/contacts/people" icon="ri-contacts-book" title={t("Contatos")} />
                <MenuItem to="/flows" icon="ri-flashlight" title={t("Fluxos")} />
                <MenuItem to="/scheduled-events" icon="ri-calendar" title={t("Eventos agendados")} />
                <ToolsMenu />
                
                {pinnedItemsArray.map((item) => {
                    if (iconMap[item]) {
                        return (
                            <MenuItem
                                key={item}
                                to={iconMap[item].to}
                                icon={iconMap[item].icon}
                                title={iconMap[item].title}
                            />
                        );
                    }

                    return null;
                })}
            </div>

            <div className="bottom-itens">
                <PipelineList disablePipelineList={true}/>
                <div className="user-avatar">
                    <UserAvatar />
                </div>
            </div>
        </div>
    );
};

export default MainMenu;
