export const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export const stringAvatar = (name, withColor = false) => {

    let regex = /[a-zA-Z0-9]/;

    if (!name || !regex.test(name)) {
        return '';
    }

    let n = name.trim();

    let splited = n.split(' ');
    let l = '';

    if (splited.length > 1) {
        l = `${splited[0][0].toUpperCase()}${splited[(splited.length - 1)][0].toUpperCase()}`;
    } else {
        l = `${splited[0][0].toUpperCase()}`;
    }

    let responce = {children: l};

    if(withColor) {
        let color = {sx: {
            bgcolor: stringToColor(n),
        }};

        responce = {...responce, ...color}; 
    }

    return responce;
}