import { createContext, useEffect, useState } from "react";
import { getCookie } from "../utils/Cookies";
import useApi from "../hooks/useApi";
import usePipeline from "../hooks/usePipeline";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/pt-br";
import "moment/locale/es";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState();
    const { apiUrl, apiInit, csrfCookie } = useApi();
    const { storagePipelineId } = usePipeline();
    const { i18n } = useTranslation();

    useEffect(() => {
        const userStorage = localStorage.getItem("me");

        if (userStorage) {
            let user = JSON.parse(userStorage);
            if (user) {
                setUser(user);
            }
        }

        //aplica o locale de acordo com o language do browser
        moment.locale(i18n.language);
    }, [i18n]);

    const getUserData = (user) => {
        let data = user || {};
        return data;
    };

    const storageUserData = (data) => {
        let user = getUserData(data);

        localStorage.setItem("me", JSON.stringify(user));
        localStorage.setItem("avatar", data.avatar || null);
        localStorage.setItem("pinned_items", data?.pinned_items?.map(item => item.icon_name) || []);
    };

    const signin = async (email, password, options = null) => {

        let dataResponse = undefined;

        let endpoint = 'login';

        let body = {
            email: email,
            password: password
        };

        if(options?.type === 'support') {
            endpoint = 'login-support';
            body.user_id = options?.user_id;
        } 

        await csrfCookie().then(async (response) => {
            await fetch(
                apiUrl(endpoint),
                apiInit({
                    headers: {
                        "X-XSRF-TOKEN": getCookie("XSRF-TOKEN"),
                    },
                    method: "POST",
                    body: JSON.stringify(body),
                })
            ).then(async (response) => {
                if (response.status === 200) {
                    await response.json().then((data) => {
                        if (data.message !== undefined) {
                            return data.message;
                        } else {
                            storageUserData(data);
                            storagePipelineId(data.default_pipeline);
                            setUser(getUserData(data));

                            //se for pegar o lang do banco algum dia, colocar aqui
                            //i18n.changeLanguage('en');
                        }
                    });
                } else {
                    await response.json().then((data) => {
                        if (data.errors !== undefined) {
                            dataResponse = data;
                        } else {
                            dataResponse = { message: response.status + " - " + response.statusText };
                        }
                    });
                }
            });
        });
        return dataResponse;
    };

    const signup = async (
        gateway,
        seller,
        referral,
        user_name,
        email,
        phone,
        password,
        password_confirmation,
        client_type,
        client_name,
        business_segment_id,
        business_size,
        cpf_cnpj,
        address_country,
        address_state,
        address_city,
        address_district,
        address_postal_code,
        address_street,
        address_number,
        address_line1,
        address_line2,
        address_municipal_code,
        payment_cycle,
        value,
        value_with_discount,
        coupon_id,
        financial_email,
        payment_method,
        card_token
    ) => {
        return await csrfCookie().then(async (response) => {
            return await fetch(
                apiUrl("register"),
                apiInit({
                    headers: {
                        "X-XSRF-TOKEN": getCookie("XSRF-TOKEN"),
                    },
                    method: "POST",
                    body: JSON.stringify({
                        gateway: gateway,
                        seller: seller,
                        referral: referral,
                        user_name: user_name,
                        email: email,
                        phone: phone,
                        password: password,
                        password_confirmation: password_confirmation,
                        client_name: client_name,
                        client_type: client_type,
                        business_segment_id: business_segment_id,
                        business_size: business_size,
                        cpf_cnpj: cpf_cnpj,
                        address_country: address_country,
                        address_state: address_state,
                        address_city: address_city,
                        address_district: address_district,
                        address_postal_code: address_postal_code,
                        address_street: address_street,
                        address_number: address_number,
                        address_line1: address_line1,
                        address_line2: address_line2,
                        address_municipal_code: address_municipal_code,
                        payment_cycle: payment_cycle,
                        value: value,
                        value_with_discount: value_with_discount,
                        coupon_id: coupon_id,
                        financial_email: financial_email,
                        payment_method: payment_method,
                        card_token: card_token
                        
                    }),
                })
            ).then((response) => {
                return response;
            });
        });
    };

    const cleanup = () => {
        localStorage.removeItem("me");
        setUser(null);
    };

    const signout = (callback = null) => {
        fetch(apiUrl("logout"), {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            method: "GET",
            credentials: "include",
        }).then((response) => {
            if (response.status === 200) {
                cleanup();
                if (callback) {
                    callback();
                }
            }
        });
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                signed: !!user,
                verified: !!user?.verified,
                signin,
                signup,
                signout,
                cleanup,
                subscription_expired: !!user?.subscription_expired,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
