import { createContext, useState, useEffect } from "react";
import useApi from "../hooks/useApi";
import useAuth from "../hooks/useAuth";

export const PinnedItemsContext = createContext();

export const PinnedItemsProvider = ({ children }) => {
    const { apiUrl, apiInit, csrfCookie, csrfHeader } = useApi();
    const { user } = useAuth();

    const [pinnedItems, setPinnedItems] = useState("");
    const pinnedItemsArray = pinnedItems.split(",").filter(item => item);

    useEffect(() => {
        if (user) {
            const userPinnedItems = localStorage.getItem("pinned_items");
            setPinnedItems(userPinnedItems || "");
        } else {
            setPinnedItems("");
        }
    }, [user]);

    useEffect(() => {
        if (user) {
            localStorage.setItem("pinned_items", pinnedItems);
        }
    }, [pinnedItems, user]);

    const savePinnedItem = async (item) => {
        await csrfCookie();
        fetch(apiUrl(`user-menu-settings`), apiInit({
            ...csrfHeader(),
            method: "POST",
            body: JSON.stringify({ icon_name: item })
        }))
        .then((response) => {
            if (response.status === 200) {
                setPinnedItems(prev => prev ? `${prev},${item}` : item);
            }
        })
        .catch((error) => console.error(error));
    };

    const deletePinnedItem = async (itemName) => {
        await csrfCookie();
        fetch(apiUrl(`user-menu-settings/${itemName}`), apiInit({
            ...csrfHeader(),
            method: "DELETE"
        }))
        .then((response) => {
            if (response.status === 200) {
                setPinnedItems(prev => prev.split(",").filter(item => item !== itemName).join(","));
            }
        })
        .catch((error) => console.error(error));
    };

    return (
        <PinnedItemsContext.Provider value={{ pinnedItems, pinnedItemsArray, savePinnedItem, deletePinnedItem }}>
            {children}
        </PinnedItemsContext.Provider>
    );
};
