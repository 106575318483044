import React from 'react';

const GenericPrivacyPolicy = () => {

    return (
        <div style={{padding:10}}>
			<h1>POLÍTICA DE PRIVACIDADE DE DADOS</h1>

			<p>A sua privacidade é importante para nós. Nossa empresa está comprometida em proteger as informações pessoais que você compartilha conosco. Esta Política de Privacidade de Dados descreve como coletamos, usamos, compartilhamos e protegemos essas informações.</p>

			<h2>1.Informações Coletadas</h2>
			<p>Podemos coletar informações pessoais, como nome, endereço, número de telefone e endereço de e-mail, quando você realiza uma compra ou se comunica conosco. Essas informações são exclusivamente utilizadas para processar pedidos, fornecer suporte ao cliente e melhorar nossos serviços.</p>
			
			<h2>2. Uso de Informações</h2>
			<p>Utilizamos suas informações pessoais apenas para os fins específicos para os quais foram coletadas, a menos que obtenhamos seu consentimento ou conforme exigido por lei.</p>

			<h2>3. Segurança de Dados</h2>
			<p>Implementamos medidas de segurança técnicas e organizacionais para proteger suas informações contra acesso não autorizado, uso indevido ou divulgação. No entanto, não podemos garantir a segurança absoluta das informações transmitidas pela internet.</p>
			
			<h2>4. Acesso e Controle</h2>
			<p>Você tem o direito de acessar, corrigir ou excluir suas informações pessoais a qualquer momento. Se você deseja exercer esses direitos ou tiver dúvidas sobre nossa Política de Privacidade de Dados, entre em contato conosco.</p>
			
			<h2>5. Alterações na Política</h2>
			<p>Podemos atualizar esta Política de Privacidade de Dados periodicamente para refletir mudanças em nossas práticas de privacidade. Recomendamos que você reveja esta política regularmente para estar ciente de como protegemos suas informações.</p>
			<p></p>
			<p>Ao continuar a usar nossos serviços, você concorda com a coleta e o uso de suas informações pessoais conforme descrito nesta Política de Privacidade de Dados.</p>
			<p></p>
			<p>Para quaisquer dúvidas ou preocupações sobre esta política ou sobre a privacidade de seus dados, entre em contato conosco.</p>
		</div>
    );
}

export default GenericPrivacyPolicy;