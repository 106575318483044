import { isIP } from "is-ip";

export const getApiBaseUrl = () => {

    const url = new URL(window.location.origin);

    const host = getWhiteLabelDomain();

    const apiSubdomain = process.env.REACT_APP_API_SUBDOMAIN || 'teste';

    const hostname = url.hostname;

    const apiHost = isIP(hostname) ? hostname : `${apiSubdomain}.${host}`;

    url.port = '';
    url.host = apiHost;

    return removeTrailingSlash(url.toString());

}

export const getWhiteLabelDomain = () => {

    const url = new URL(window.location.origin);

    const hostname = url.hostname;

    var apiHost = "";

    if(isIP(hostname)) {
        apiHost = hostname;
    } else {
        const hostArr = getWhiteLabelDomainPieces(hostname);
        apiHost = hostArr.join('.');
    }

    return apiHost;

}

export const getWhiteLabelDomainPieces = (hostname) => {

    const host = hostname;

    const hostArr = host.split('.');
    hostArr.shift();

    return hostArr;

}

export const removeTrailingSlash = (str) => {
    return str.replace(/\/+$/, '');
}
