import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

const MenuItemIcon = styled(MenuItem)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    height: "120px",
    paddingTop: "30px",
    borderRadius: "8px",
    position: "relative",
    '&:hover .pin-icon': {
        visibility: 'visible',
    }
}));

export default MenuItemIcon;
