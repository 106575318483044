import { Avatar, IconButton, Menu, MenuItem, styled } from "@mui/material";
import useApi from "../../hooks/useApi";
import useAuth from "../../hooks/useAuth";
import { useCallback, useState } from "react";
/* import { stringAvatar } from "../../utils/stringAvatar"; */
import { useTranslation } from "react-i18next";
import RemixIcon from "../icons/RemixIcon";
import { useNavigate } from "react-router-dom";

const SelectDropdown = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 4,
        bottom: 64,
        top: "auto !important",
        left: "12px !important",
        minWidth: 200,
        maxHeight: 500,
        border: "var(--borders)",
        boxShadow:
            "0px 1px 2px rgba(32, 37, 41, 0.05), 0px 4px 4px rgba(32, 37, 41, 0.05)",
        "& .MuiMenu-list": {
            padding: "0",
        },
        "& .MuiMenuItem-root": {
            padding: "16px 36px 16px 12px",
            height: 48,
            fontSize: 14,
            "& span": {
                marginRight: 10,
            },
            "& i": {
                color: "var(--text-color-light)",
                fontSize: 16,
                marginRight: 10,
            },
            "&.header-item": {
                borderBottom: "1px solid var(--outline-color)",
                cursor: "default",
                "& .MuiIconButton-root": {
                    color: "var(--text-color-light)",
                    fontSize: 18,
                    position: "absolute",
                    right: 5,
                    "& i": {
                        marginRight: 0,
                        marginLeft: 0,
                    },
                },
                "&:hover": {
                    backgroundColor: "var(--white)",
                },
                "&.Mui-focusVisible": {
                    backgroundColor: "var(--white)",
                },
            },
            "&:hover": {
                backgroundColor: "var(--background-color-light)",
            },
        },
    },
}));

export default function UserAvatar() {
    const { t } = useTranslation();

    const { signout, user } = useAuth();
    const { baseUrl } = useApi();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = useCallback(() => {
        signout();
    }, [signout]);

    return (
        <>
            <Avatar
                onClick={handleClick}
                src={user.avatar && `${baseUrl()}/img/avatars/${user.avatar}`}
            />

            <SelectDropdown
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose} className="header-item">
                    <span>{user.name}</span>
                    <IconButton
                        onClick={() => {
                            logout();
                        }}
                    >
                        <RemixIcon className="ri-logout-box-line" />
                    </IconButton>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        navigate(`/settings/accounts/${user.id}`);
                        handleClose();
                    }}
                >
                    <RemixIcon className="ri-account-circle-line" />
                    <span>{t("Conta")}</span>
                </MenuItem>
            </SelectDropdown>
        </>
    );
}
