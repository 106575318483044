import React from "react";
import { Link, useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

const MenuItem = ({ icon, to, title }) => {
    let pathname = useLocation().pathname;

    let page = to;
    let isPage = pathname.includes(page);

    let activeClass = isPage ? " active" : "";
    
    let iconType = isPage ? "fill" : "line";

    return (
        <Tooltip title={title} placement="right" arrow disableInteractive>
            <Link className={`menu-item ${activeClass}`} to={page}>
                {/* há ícones que não são do tipo 'fill' ou 'line' */}
                <i className={icon === "ri-list-check-3" ? `${icon}` : `${icon}-${iconType}`}></i>
            </Link>
        </Tooltip>
    );
};

export default MenuItem;
