import { forwardRef, useCallback } from "react";
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import IconButton from "@mui/material/IconButton";
import ChannelMailIcon from '../../components/icons/channelMailIcon';
import IconBlock from '../../components/icons/IconBlock';
import RemixIcon from '../../components/icons/RemixIcon';


interface ReportCompleteProps extends CustomContentProps {
  allowDownload?: boolean;
}

const ReceiveMail = forwardRef<HTMLDivElement, ReportCompleteProps>(
  ({ id, ...props }, ref) => {

    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <SnackbarContent ref={ref} className="notistack-MuiContent notistack-MuiContent-light">
        <div className="notistack-snackbar notistack-message">
          <IconBlock>
            <ChannelMailIcon/>
          </IconBlock>
          {props.message}
        </div>
        <div className="notistack-action">
          <IconButton onClick={handleDismiss}>
            <RemixIcon className="ri-close-line"></RemixIcon>
          </IconButton>
        </div>
      </SnackbarContent>
    );
  }
);

//ReceiveMail.displayName = "ReportComplete";

export default ReceiveMail;
