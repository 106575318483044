import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import { stringAvatar } from '../../utils/stringAvatar';
import PipelineStepList from '../../components/main-menu/pipelineStepList';
import usePipeline from "../../hooks/usePipeline";
import useApi from "../../hooks/useApi";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import SelectDropdown from '../select-dropdown/selectDropdown';

export default function PipelineList({ disablePipelineList }) {

    const theme = useTheme();

    const { t } = useTranslation();

    const [pipelines, setPipelines] = useState([]);
    const [pipelineSteps, setPipelineSteps] = useState([]);

    const { pipelineId, storagePipelineId, cleanupPipelineStepId } = usePipeline();

    const [selectedPipelineId, setSelectedPipelineId] = useState(pipelineId);

    const [selectedPipelineChildren, setSelectedPipelineChildren] = useState({ children: <i className="ri-arrow-up-s-fill"></i> });

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const pipelineClick = (event) => {

        let selectedPipelineId = parseInt(event.currentTarget.getAttribute('p-id'));

        changePipeline(selectedPipelineId);

        handleClose();

    };

    const changePipeline = (pipelineId) => {

        window._pipeline_steps = null;

        setSelectedPipelineId(pipelineId);
        storagePipelineId(pipelineId);

        cleanupPipelineStepId();

    };

    const { apiUrl, apiInit } = useApi();

    useEffect(() => {

        fetch(apiUrl('pipelines'), apiInit()).then(response => {

            /* if (response.status === 401) {
                cleanup();
            } */

            response.json().then((data) => {

                let p = data.data || [];

                p.forEach(function (pipeline, i) {
                    let s = pipeline.steps;
                    s.forEach(function (step, i) {
                        let colors = theme.palette.chromaticColors.find(({ defaultColor }) => defaultColor === step.color);
                        if (colors) {
                            step.colors = colors;
                        } else {
                            step.colors = { "id": null, "veryLightColor": null, "defaultColor": null, "lightColor": null, "darkColor": null, "veryDarkColor": null };
                        }

                    });
                });

                if (!disablePipelineList) {
                    setPipelines(p);
                }

                const selectedPipeline = p.find(({ id }) => id === selectedPipelineId);

                if (selectedPipeline) {

                    setSelectedPipelineChildren({ ...stringAvatar(selectedPipeline.name) });
                    setPipelineSteps(selectedPipeline.steps);

                }

            });

        }).catch(error => console.error(error));
    }, [apiUrl, apiInit, selectedPipelineId, disablePipelineList, theme]);

    return (<>
        <div className="pipeline-step">
            <PipelineStepList pipelineSteps={pipelineSteps} />
        </div>
        {!disablePipelineList && <div className="pipeline-step-avatar">
            <Avatar
                id="pipeline-list-button"
                aria-controls={open ? 'pipeline-list-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                {...selectedPipelineChildren}
                title={t("Funis")}
            />

            <SelectDropdown
                id="pipeline-list-menu"
                MenuListProps={{ 'aria-labelledby': 'pipeline-list-button', }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose} className="header-item">
                    <Link to="/" disabled>{t("Criar funil")}</Link> <i className="ri-settings-line"></i>
                </MenuItem>
                {pipelines.map((option, index) => (
                    <MenuItem
                        p-id={option.id}
                        key={option.id}
                        selected={option.id === selectedPipelineId}
                        onClick={pipelineClick}
                    >
                        <Avatar {...stringAvatar(option.name)} /> <span>{option.name}</span>
                    </MenuItem>
                ))}
            </SelectDropdown>
        </div>}
    </>);
}