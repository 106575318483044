import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => {

    return (
        <div className="loading" style={{display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "25px"}}>
          <CircularProgress color="inherit" size={20}/>
        </div>
    )

}

export default Loading