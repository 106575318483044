import React from 'react';

const PrivacyPolicy = () => {

    return (
        <div style={{padding:10}}>
			<h1>Política de Privacidade</h1>

			<h2>1. INFORMAÇÕES GERAIS</h2>
			<p>1.1 A <strong>NETWALL TECNOLOGIA LTDA</strong>. pessoa jurídica de direito privado, inscrita no CNPJ nº. 04.925.128/0001-17, com sede nesta Capital/RS, disponibiliza, por meio deste documento, a sua política de privacidade, que dispõe sobre o Tratamento de Dados Pessoais de nossos usuários/clientes realizado no âmbito das suas soluções comerciais de conexão, atendimento virtual e intermediação de compras e vendas de produtos de estabelecimentos por meio de plataforma própria, bem como outras soluções que já existam ou venham a existir (“Serviços Netwall”).</p>
			<p>1.2 Destina-se a presente Política de Privacidade a regular a forma em que é realizado o Tratamento de Dados Pessoais, na qualidade de controladora ou de operadora de dados, nos termos definidos pela LGPD, dos Dados Pessoais daqueles que acessam, cadastram-se ou de qualquer forma utilizam os seus Serviços, como consumidor (doravante designados em conjunto ou individualmente como “Usuários” ou “Usuário”, respectivamente).</p>
			

			<h2>2. RECOLHIMENTO DOS DADOS PESSOAIS DO USUÁRIO/CLIENTE</h2>
			<p>2.1 Os dados pessoais do usuário/cliente são recolhidos pela plataforma da seguinte forma:</p>
			<p>a) Quando o usuário cria uma conta/perfil na plataforma da empresa: esses dados são os dados de identificação básicos, como: nome completo, e-mail, endereço, documentos de identificação (RG, CPF, CNH, documento funcional). A partir deles, podemos identificar o usuário/cliente, além de garantir uma maior segurança e bem-estar às suas necessidades. Ficam cientes os usuários/clientes de que seu perfil na plataforma estará acessível pela empresa para uso exclusivo das funcionalidades da plataforma.</p>
			<p>b) Os dados bancários do usuário não são coletado, tratados ou armazenados pela empresa. Ao evoluir o seu cadastro até o tópico de preenchimento de dados bancários, a empresa utiliza um gateway de pagamento terceirizado. Assim, utilizando-se de tecnologia própria e de acordo com as normas do PCI DSS (Payment Card Industry Data Security Standard) dentre outras normas de segurança, são coletados, armazenados e tratados os dados bancários conforme Termo de Privacidade de forma autônoma e independente em relação à Netwall.</p>
			<p>c) Quando um usuário/cliente acessa as páginas do site: as informações sobre interação e acesso são coletadas pela empresa para garantir uma melhor experiência ao usuário/cliente. Estes dados podem tratar sobre as palavras-chaves utilizadas em uma busca, o compartilhamento de um documento específico, comentários, visualizações de páginas, perfis, a URL de onde o usuário/cliente provêm, o navegador que utilizam e seus IPs de acesso, dentre outras que poderão ser armazenadas e retidas.</p>
			<p>d) Por intermédio de terceiro: o site e/ou plataforma da coletora dos dados recebe dados de empresas terceiras, a qual o usuário autorizou o acesso, quando faz/fez login com o seu perfil de um desses sites. A utilização desses dados é autorizada previamente pelos usuários junto ao terceiro em questão.</p>
			<p>Parágrafo único: Nossa plataforma não coleta ou trata dados bancários de nossos clientes e usuários. Ao avançar o procedimento de cadastro, ao escolher a forma de pagamento, automaticamente, o usuário/cliente é redirecionado para plataforma de empresa terceira.</p>


			<h2>3. DADOS PESSOAIS RECOLHIDOS DO USUÁRIO/CLIENTE</h2>
			<p>3.1 Os dados pessoais do usuário/cliente que podem ser recolhidos diretamente por nossa plataforma são os seguintes:</p>
			<p>a) Dados para a criação da conta/perfil e dados necessários para a emissão da Nota Fiscal, tais como nome completo, e-mail, endereço, número do documento de (RG, CPF, CNH, documento funcional), dentre outros.</p>
			<p>b) Dados para otimização da navegação: acesso a páginas, palavras-chave utilizadas na busca, recomendações, comentários, interação com outros perfis e usuários, perfis seguidos, endereço de IP.</p>
			<p>c) Newsletter: o e-mail cadastrado pelo visitante que optar por se inscrever na Newsletter será coletado e armazenado até que o usuário solicite o descadastro.</p>
			<p>d) Dados relacionados a contratos: diante da formalização do contrato de compra e venda e/ou de prestação de serviços entre a plataforma e o usuário/cliente poderão ser coletados e armazenados dados relativos à execução contratual, inclusive número de celular para a realização das comunicações entre a empresa e o usuário/cliente, seja por ligações, mensagens, push ou por intermédio de aplicativos ou outros meios de comunicação digital não mencionados aqui.</p>

			<h2>4. UTILIZAÇÃO DOS DADOS PESSOAIS DO USUÁRIO/CLIENTE</h2>
			<p>4.1 Os dados pessoais do usuário/cliente coletados e armazenados pela plataforma da ora coletora e tratadora dos dados tem por finalidade alternativamente ou cumulativamente:</p>
			<p >a) Comodidade do usuário/cliente: aprimorar o produto e/ou serviço oferecido, facilitar, agilizar e cumprir os compromissos estabelecidos entre o usuário/cliente e a empresa, melhorar a experiência dos usuários/cliente e fornecer funcionalidades específicas a depender das características básicas do usuário/cliente;</p>
			<p>b) Melhorias do site e plataforma: compreender como o usuário/cliente utiliza os serviços do site e da plataforma, para ajudar no desenvolvimento de negócios e técnicas.</p>
			<p>c) Anúncios: apresentar anúncios personalizados para o usuário/cliente com base nos dados fornecidos;</p>
			<p>d) Comercial: os dados são usados para personalizar o conteúdo oferecido e gerar subsídio ao site e à plataforma para a melhora da qualidade no funcionamento dos serviços.</p>
			<p>e) Previsão do perfil do usuário: tratamento automatizados de dados pessoais para avaliar o uso no site e na plataforma;</p>
			<p>f) Dados de cadastro: para permitir o acesso do usuário a determinados conteúdos do site e da plataforma, exclusivo para usuários cadastrados;</p>
			<p>g) Dados de contrato: conferir às partes segurança jurídica e facilitar a conclusão do negócio;</p>
			<p>4.2 O tratamento de dados pessoais para finalidades não previstas nesta Política de Privacidade somente ocorrerá mediante comunicação e autorização prévia ao usuário/cliente, de modo que os direitos e obrigações aqui previstos permanecem aplicáveis.</p>

			<h2>5. TEMPO ARMAZENAMENTO DOS DADOS PESSOAIS</h2>
			<p>5.1 Os dados pessoais do usuário/clientes são armazenados pelo site e plataforma durante o período de 10 (dez) anos após o término da prestação do serviço ou o cumprimento das finalidades previstas no presente documento, conforme o disposto no inciso <a href="https://www.jusbrasil.com.br/topicos/200399061/inciso-i-do-artigo-15-da-lei-n-13709-de-14-de-agosto-de-2018" target="_blank" rel="noreferrer">I</a> do artigo <a href="https://www.jusbrasil.com.br/topicos/200399064/artigo-15-da-lei-n-13709-de-14-de-agosto-de-2018" target="_blank" rel="noreferrer">15</a> da Lei <a href="https://presrepublica.jusbrasil.com.br/legislacao/612902269/lei-13709-18" target="_blank" rel="noreferrer">13.709</a>/18.</p>
			<p>5.2 Os dados podem ser removidos ou anonimizados a pedido do usuário/cliente, formalmente e por escrito, excetuando os casos em que a lei oferecer outro tratamento.</p>
			<p>5.3 Ainda, os dados pessoais dos usuários/clientes apenas podem ser conservados após o término de seu tratamento nas seguintes hipóteses previstas no artigo 16 da referida lei:</p>
			<p>I - cumprimento de obrigação legal ou regulatória pelo controlador;</p>
			<p>II - estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;</p>
			<p>III - transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei;</p>
			<p>IV - uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.</p>

			<h2>6. SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS</h2>
			<p>6.1 O site e plataforma se compromete a aplicar as medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados.</p>
			<p>6.2 Os dados relativos a cartões de débito e crédito não são armazenados ou tratados pela empresa NETWALL, tal qual descrito no item 2.1 alínea “b” deste Termo.</p>
			<p>6.3 A plataforma não se exime de responsabilidade, salvo nos casos de culpa exclusiva de terceiros, como em caso de ataque de hackers ou crackers, ou, ainda, culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a terceiros. O site se compromete a comunicar o usuário/cliente em caso de alguma violação de segurança dos seus dados pessoais.</p>
			<p>6.4 Os dados pessoais armazenados são tratados com confidencialidade, dentro dos limites legais. No entanto, podemos divulgar suas informações pessoais caso sejamos obrigados pela lei para fazê-lo, por ordem judicial ou se você violar nossos Termos de Serviço.</p>

			<h2>7. COMPARTILHAMENTO DOS DADOS</h2>
			<p>7.1 A NETWALL não compartilha dados com terceiros, salvo as informações essenciais e necessárias para a emissão de Nota Fiscal do produto ou serviço adquirido com terceiros ou empresas terceirizadas para tal finalidade.</p>

			<h2>8. TRANSFERÊNCIA DOS DADOS PESSOAIS ARMAZENADOS À TERCEIROS</h2>
			<p>8.1 Os terceiros indicados recebem os dados na medida do necessário para permitir que eles realizem os serviços contratados.</p>
			<p>8.2 Com relação aos fornecedores de serviços terceirizados como processadores de transação de pagamento, informamos que cada qual tem sua própria política de privacidade e proteção de dados. Desse modo, recomendamos a leitura das suas políticas de privacidade para compreensão de quais informações pessoais serão usadas por esses fornecedores, as quais podem ser acessadas diretamente na respectiva plataforma de cada empresa terceirizada.</p>
			<p>8.3 Os fornecedores podem ser localizados ou possuir instalações localizadas em países diferentes. Nessas condições, os dados pessoais transferidos podem se sujeitar às leis de jurisdições nas quais o fornecedor de serviço ou suas instalações estão localizados.</p>
			<p>8.4 Ao acessar nossos serviços e prover suas informações, você está consentindo o processamento, transferência e armazenamento desta informação também em relação a outros países.</p>
			<p>8.5 Ao acessar nossos serviços você poderá ser redirecionado para um aplicativo ou site de terceiros. Assim, você não será mais regido por essa Política de Privacidade ou pelos Termos de Serviço da nossa plataforma. Não somos responsáveis pelas práticas de privacidade de outros sites e lhe incentivamos a ler as declarações de privacidade de cada empresa terceirizada.</p>
			
			<h2>9. DIREITOS DOS TITULARES DOS DADOS PESSOAIS</h2>
			<p>9.1 Aos Usuários/clientes, enquanto Titulares dos Dados Pessoais que estiverem sob o controle da empresa, será garantido o exercício dos seguintes direitos, que deverão ser exercidos na forma da Cláusula 11 abaixo:</p>
			<p>(a) Acessar, revisar, saber se realizamos algum tratamento com seus Dados Pessoais e requisitar uma cópia eletrônica da informação que temos sobre usuário/clientes;</p>
			<p>(b) Corrigir ou solicitar a correção de Dados Pessoais incompletos, inexatos ou desatualizados;</p>
			<p>(c) Solicitar a anonimização, bloqueio ou eliminação de Dados Pessoais desnecessários e excessivos;</p>
			<p>(d) Solicitar a portabilidade dos Dados Pessoais a outro prestador de serviços similares, desde que respeitadas as informações confidenciais da empresa;</p>
			<p>(e) Solicitar a eliminação dos Dados Pessoais coletados e utilizados com base no seu consentimento;</p>
			<p>(f) Obter informações sobre as entidades públicas ou privadas com as quais compartilhamos os seus Dados Pessoais;</p>
			<p>(g) Quando a atividade de tratamento necessitar do seu consentimento, você pode se negar a consentir. Nesse caso, a empresa lhe informará sobre as consequências da não realização de tal atividade; e</p>
			<p>(h) Quando a atividade de tratamento necessitar do seu consentimento, a qualquer momento você poderá revogá-lo.</p>
			<p>9.2 Para o exercício dos direitos do titular acima previstos, os Usuários/clientes deverão entrar em contato com o encarregado de dados, nos termos do Capítulo 11 desta Política de Privacidade. A solicitação será analisada no prazo de até 30 (trinta) dias úteis, salvo em hipóteses de prazos inferiores previstas na legislação de proteção de dados, e, após a análise, a solicitação do usuário/cliente será respondida com as informações requeridas ou com as justificativas para a impossibilidade de disponibilizá-las.</p>
			<p>9.3 A empresa ressalta que a exclusão dos Dados Pessoais dos usuários/clientes mediante suas solicitações poderá acarretar na alteração e/ou na impossibilidade de prestação dos Serviços prestados pela empresa. Dessa forma, eventual solicitação de exclusão de Dados Pessoais poderá implicar na impossibilidade de acesso a determinadas áreas do website e/ou da plataforma, bem como visualização de determinados conteúdos ou solicitação de produtos dentro de sua plataforma, além de promoções, disponibilização de cupons de desconto, prêmios e outras campanhas realizadas por meio da utilização da Plataforma pelo usuário/cliente.</p>
			<p>9.4 O pedido de exclusão de dados não implica que a empresa não poderá utilizar dados de forma distinta, sem qualquer identificação pessoal, não individualizada e anonimizada ou dentro das hipóteses previstas em lei.</p>
			
			<h2>10. COOKIES OU DADOS DE NAVEGAÇÃO</h2>
			<p>10.1 Os cookies referem-se a arquivos de texto enviados pela plataforma ao computador do usuário/cliente e que nele ficam armazenados, com informações relacionadas à navegação no site. Tais informações são relacionadas aos dados de acesso como local e horário de acesso e são armazenadas pelo navegador do usuário/cliente para que o servidor da plataforma possa lê-las posteriormente a fim de personalizar os serviços da plataforma.</p>
			<p>10.2 O usuário/cliente do site e plataforma da empresa manifesta conhecer e aceitar que pode ser utilizado um sistema de coleta de dados de navegação mediante à utilização de cookies.</p>
			<p>10.3 O Usuário, ao acessar o website e/ou utilizar os serviços, fornecerá o seu consentimento para a utilização de cookies e demais tecnologias de armazenamento, por meio de aceite quando do primeiro acesso ao website e/ou da primeira utilização dos serviços.</p>
			<p>10.4 O cookie persistente permanece no disco rígido do usuário/cliente depois que o navegador é fechado e será usado pelo navegador em visitas subsequentes ao site. Os cookies persistentes podem ser removidos seguindo as instruções do seu navegador (Internet Explorer, Google Chrome, Mozilla Firefox, Safari, dentre outros). Já o cookie de sessão é temporário e desaparece depois que o navegador é fechado. É possível redefinir seu navegador da web para recusar todos os cookies, porém alguns recursos da plataforma podem não funcionar corretamente se a capacidade de aceitar cookies estiver desabilitada.</p>
			
			<h2>11. CONSENTIMENTO</h2>
			<p>11.1 Ao utilizar os serviços e fornecer as informações pessoais na plataforma, o usuário/cliente está consentindo com a presente Política de Privacidade.</p>
			<p>11.2 O usuário/cliente, ao cadastrar-se, manifesta conhecer e pode exercitar seus direitos de cancelar seu cadastro, acessar e atualizar seus dados pessoais e garante a veracidade das informações por ele disponibilizadas.</p>
			<p>11.3 O usuário/cliente tem direito de retirar o seu consentimento a qualquer tempo, para tanto deve entrar em contato através do e-mail: dpo@netwall.com.br.</p>
			
			<h2>12. ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE</h2>
			<p>12.1 Reservamos o direito de modificar essa Política de Privacidade a qualquer momento, então, é recomendável que o usuário/cliente revise-a com frequência.</p>
			<p>12.2 As alterações e esclarecimentos vão surtir efeito imediatamente após sua publicação na plataforma. Quando realizadas alterações os usuários/clientes serão notificados. Ao utilizar o serviço ou fornecer informações pessoais após eventuais modificações, o usuário/cliente demonstra sua concordância com as novas normas.</p>
			<p>12.3 Diante da fusão ou venda da plataforma à outra empresa os dados dos usuários/clientes podem ser transferidos para os novos proprietários para que a permanência dos serviços oferecidos.</p>
			
			<h2>13. JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS</h2>
			<p>13.1 Para a solução de controvérsias decorrentes do presente instrumento será aplicado integralmente o Direito brasileiro.</p>
			<p>13.2 Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra a sede da empresa.</p>
		</div>
    );
}

export default PrivacyPolicy;