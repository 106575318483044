import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import useApi from "../../hooks/useApi";
import useWhiteLabel from "../../hooks/useWhiteLabel";


const WhiteLabel = () => {

    const { apiUrl, apiInit } = useApi();

    const [manifestResponse, setManifestResponse] = useState({});

    const {isWhiteLabel, favicon, logo192, manifest} = useWhiteLabel();

    const isWl = isWhiteLabel();

    useEffect(() => {
        if(isWl){
            fetch(apiUrl(`white-label/storage/manifest`), apiInit())
            .then(response => {
                response.json().then(data => {
                    setManifestResponse(prevState => { return { ...prevState, ...data } })
                })
            });
        }

    }, [apiUrl, apiInit, isWl]);


    const appName = isWl ? manifestResponse?.short_name : process.env.REACT_APP_NAME;

 
    return (
        <Helmet>
            <title>{appName}</title>
            <link rel="icon" type="image/png" href={favicon} />
            <link rel="apple-touch-icon" href={logo192} />
            <link rel="manifest" href={manifest} />
        </Helmet>
    );

}

export default WhiteLabel;
