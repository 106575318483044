import React from 'react';

const PipelineStepItem = (props) => {


    const activeClass = props.active ? ' active' : '';
    
     return (
        <div className={`pipeline-step-item ${activeClass}`} pipeline-step-id={props.id} style={{background:props.color}} onClick={props.onClick} title={props.title}></div>
     );  


}

export default PipelineStepItem;