import { createContext } from "react";
import { isIP } from "is-ip";
import { getWhiteLabelDomain } from "../utils/whiteLabelUrl";
import useApi from "../hooks/useApi";

export const WhiteLabelContext = createContext({});

export const WhiteLabelProvider = ({ children }) => {

    const { apiUrl } = useApi();

    const isWhiteLabel = () => {

        const domain = getWhiteLabelDomain();

        if(!isIP(domain)) {
            const isWhiteLabel = domain !== process.env.REACT_APP_DOMAIN;
            return isWhiteLabel;
        }

        return false;

    }

    const isWl = isWhiteLabel();

    const favicon = isWl ? apiUrl("white-label/storage/file/favicon.ico") : '/logos/favicon.ico';;

    const logo192 = isWl ? apiUrl("white-label/storage/file/logo192.png") : '/logos/logo192.png';

    const logoBlack = isWl ? apiUrl("white-label/storage/file/logo_black.png") : '/logos/logo_black.png';

    const manifest = isWl ? apiUrl("white-label/storage/manifest") : '/manifest.json';

    return (
        <WhiteLabelContext.Provider value={{ isWhiteLabel, favicon, logo192, logoBlack, manifest }}>
            {children}
        </WhiteLabelContext.Provider>
    );
};