import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

const SelectDropdown = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 4,
        bottom: 64,
        top: "auto !important",
        left: "12px !important",
        minWidth: 200,
        minHeight: 236,
        maxHeight: 500,
        border: "var(--borders)",
        boxShadow: '0px 1px 2px rgba(32, 37, 41, 0.05), 0px 4px 4px rgba(32, 37, 41, 0.05)',
        '& .MuiMenu-list': {
            padding: '0',
        },
        '& .MuiMenuItem-root': {
            padding: "16px 36px 16px 12px",
            height: 48,
            fontSize: 14,
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: 12,
                marginLeft: 12,
            },
            "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                marginRight: 12,
                borderRadius: 4,
                fontSize: 14,
                backgroundColor: "var(--avatar-bg-color)",
            },
            "&.Mui-selected": {
                backgroundColor: 'var(--background-color-light)',
                "&::after": {
                    content: '"\\eb7b"',
                    fontFamily: "'remixicon' !important",
                    fontStyle: "normal",
                    marginLeft: 10,
                    position: "absolute",
                    right: 15,
                    fontSize: 16,
                },
            },
            "&.header-item": {
                borderBottom: "1px solid var(--outline-color)",
                pointerEvents: "none",
                "& i": {
                    color: "var(--text-color-light)",
                    fontSize: 18,
                    position: "absolute",
                    right: 15
                },
                "& a": {
                    color: "var(--theme-color-dark)",
                    pointerEvents: "all",
                    fontSize: 16,
                },
            },
            '&:hover': {
                backgroundColor: 'var(--background-color-light)',
            },
        },
    },
}));

export default SelectDropdown;