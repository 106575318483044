import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import RemixIcon from "../icons/RemixIcon";
import IconBlock from "../icons/IconBlock";
import MenuItemIcon from "../mui-mod/MenuItemIcon";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import usePinnedItems from "../../hooks/usePinnedItems";

import useAuth from "../../hooks/useAuth";

const PinIcon = styled(RemixIcon)(({ theme }) => ({
    position: "absolute",
    top: "5px",
    right: "5px",
    cursor: "pointer",
    visibility: "hidden"
}));

const ToolsMenu = () => {
    const { t } = useTranslation();
    const { user } = useAuth();

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const [active, setActive] = useState("");

    const { pinnedItemsArray, savePinnedItem, deletePinnedItem } = usePinnedItems();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setActive("active");
    };

    const handleClose = () => {
        setAnchorEl(null);
        setActive("");
    };

    const navigateTo = (path) => {
        navigate(path);
        handleClose();
    };

    const handlePin = (event, itemName) => {
        event.stopPropagation();
        event.preventDefault();

        const isPinned = pinnedItemsArray.some(item => item === itemName);
        if (isPinned) {
            const iconNameToDelete = pinnedItemsArray.find(item => item === itemName);
            deletePinnedItem(iconNameToDelete);
        } else {
            savePinnedItem(itemName);
        }
    };

    const isPinned = (itemName) => pinnedItemsArray.some(item => item === itemName);

    return (
        <>
            <IconButton onClick={handleClick} className={`menu-item ${active}`}>
                <RemixIcon className="ri-more-fill" />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }}
                PaperProps={{
                    sx: {
                        padding: "5px 10px",
                        maxWidth: "30%",
                        marginLeft: "5px"
                    },
                }}
            >
                <Grid container>
                    <Grid item xs={4} md={4} sm={6}>
                        <MenuItemIcon onClick={() => navigateTo("/email-verification")}>
                            <PinIcon
                                className={`ri-pushpin-${isPinned("email-verification") ? 'fill' : 'line'} pin-icon`}
                                onClick={(event) => handlePin(event, "email-verification")}
                            />
                            <IconBlock color="green">
                                <RemixIcon className="ri-mail-check-fill" />
                            </IconBlock>
                            <Typography variant="smallTextRegular">{t("Verificar email")}</Typography>
                        </MenuItemIcon>
                    </Grid>
                    <Grid item xs={4} md={4} sm={6}>
                        <MenuItemIcon onClick={() => navigateTo("/referral")}>
                            <PinIcon
                                className={`ri-pushpin-${isPinned("referral") ? 'fill' : 'line'} pin-icon`}
                                onClick={(event) => handlePin(event, "referral")}
                            />
                            <IconBlock color="green">
                                <RemixIcon className="ri-gift-fill" />
                            </IconBlock>
                            <Typography
                                variant="smallTextRegular"
                                sx={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                }}
                            >
                                {t("Programa de indicações")}
                            </Typography>
                        </MenuItemIcon>
                    </Grid>
                    {user.is_admin && (
                        <Grid item xs={4} md={4} sm={6}>
                            <MenuItemIcon onClick={() => navigateTo("/contacts/import")}>
                                <PinIcon
                                    className={`ri-pushpin-${isPinned("import-contacts") ? 'fill' : 'line'} pin-icon`}
                                    onClick={(event) => handlePin(event, "import-contacts")}
                                />
                                <IconBlock color="green">
                                    <RemixIcon className="ri-upload-line" />
                                </IconBlock>
                                <Typography
                                    variant="smallTextRegular"
                                    sx={{
                                        textAlign: "center",
                                        whiteSpace: "normal",
                                    }}
                                >
                                    {t("Importar contatos")}
                                </Typography>
                            </MenuItemIcon>
                        </Grid>
                    )}
                    <Grid item xs={4} md={4} sm={6}>
                        <MenuItemIcon onClick={() => navigateTo("/contacts/batch-actions")}>
                            <PinIcon
                                className={`ri-pushpin-${isPinned("batch-actions") ? 'fill' : 'line'} pin-icon`}
                                onClick={(event) => handlePin(event, "batch-actions")}
                            />
                            <IconBlock color="green">
                                <RemixIcon className="ri-list-check-3" />
                            </IconBlock>
                            <Typography
                                variant="smallTextRegular"
                                sx={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                }}
                            >
                                {t("Ações em lote")}
                            </Typography>
                        </MenuItemIcon>
                    </Grid>
                    <Grid item xs={4} md={4} sm={6}>
                        <MenuItemIcon onClick={() => navigateTo("/settings")}>
                            <PinIcon
                                className={`ri-pushpin-${isPinned("settings") ? 'fill' : 'line'} pin-icon`}
                                onClick={(event) => handlePin(event, "settings")}
                            />
                            <IconBlock color="green">
                                <RemixIcon className="ri-settings-line" />
                            </IconBlock>
                            <Typography
                                variant="smallTextRegular"
                                sx={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                }}
                            >
                                {t("Configurações")}
                            </Typography>
                        </MenuItemIcon>
                    </Grid>
                </Grid>
            </Menu>
        </>
    );
};

export default ToolsMenu;
