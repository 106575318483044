import React from 'react';
import { useEffect } from "react";
import PipelineStepItem from '../../components/main-menu/pipelineStepItem';
import usePipeline from "../../hooks/usePipeline";

const PipelineStepList = (props) => {

    const { pipelineStepId, storagePipelineStepId } = usePipeline();

    useEffect(() => {

        if(pipelineStepId === null) {

            document.documentElement.style.setProperty('--theme-color-very-dark', 'var(--inst-color-very-dark)');
            document.documentElement.style.setProperty('--theme-color-dark', 'var(--inst-color-dark)');
            document.documentElement.style.setProperty('--theme-color-default', 'var(--inst-color-default)');
            document.documentElement.style.setProperty('--theme-color-light', 'var(--inst-color-light)');
            document.documentElement.style.setProperty('--theme-color-very-light', 'var(--inst-color-very-light)');
            
        } else {

            let pipelineStepInfo = props.pipelineSteps.find(({ id }) => id === pipelineStepId);

            if (pipelineStepInfo) {

                let colors = pipelineStepInfo.colors || null;

                if(colors) { 
                    document.documentElement.style.setProperty('--theme-color-very-dark', colors.veryDarkColor);
                    document.documentElement.style.setProperty('--theme-color-dark', colors.darkColor);
                    document.documentElement.style.setProperty('--theme-color-default', colors.defaultColor);
                    document.documentElement.style.setProperty('--theme-color-light', colors.lightColor);
                    document.documentElement.style.setProperty('--theme-color-very-light', colors.veryLightColor);
                }

            }
        }
   
        
    }, [pipelineStepId, props]);

    const changeStepId = (selectedPipelineStepId) => {

        if(pipelineStepId === selectedPipelineStepId) {

            storagePipelineStepId(null);
           
        } else {
            
            storagePipelineStepId(selectedPipelineStepId);

        }
 
    };

    const handleClick = e => {
        
        let selectedPipelineStepId = parseInt(e.target.getAttribute('pipeline-step-id'));
        changeStepId(selectedPipelineStepId);
       
    };
    
    return (
     <>   
        {props.pipelineSteps.map((item, index) => {
            return <PipelineStepItem  key={index} id={item.id} active={item.id === pipelineStepId ? true : false} color={item.colors.lightColor} title={item.name} onClick={handleClick}/>
        })}
    </>  
    );

}

export default PipelineStepList;