import { createContext } from "react";
import { useNavigate } from "react-router-dom";
import useWhiteLabel from "../hooks/useWhiteLabel";

export const DesktopNotificationContext = createContext({});

export const DesktopNotificationProvider = ({ children }) => {

    const navigate = useNavigate();
    const { logo192 } = useWhiteLabel();

    const newMessage = (title, options) => {

        const notification = new Notification(title || '', options);

        //define a ação da notificação
        notification.onclick = (event) => {

            event.preventDefault(); // prevent the browser from focusing the Notification's tab

            const d = event?.target?.data;

            if (d) {

                if (d?.action) {

                    if (d?.contact_id) {
                        let url = `/chat/${d?.contact_id}`;
                        console.log(url);
                        navigate(url);
                    }
                }

            }

        }

        return notification;

    }

    const makeNotification = (title, body, tag, data) => {

        const funcMap = {
            "new_message": (title, options) => {
                return newMessage(title, options);
            } 
        }

        const options = {
            body: body || '',
            icon: logo192,
            tag: tag || '',
            data: data,
        };

        if(options?.data?.action) {
            return funcMap[options?.data?.action](title, options);
        }

        return false;

    };

    return (
        <DesktopNotificationContext.Provider value={{ makeNotification }}>
            {children}
        </DesktopNotificationContext.Provider>
    );
};