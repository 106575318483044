import { SnackbarProvider as NotistackSnackbarProvider, useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import RemixIcon from '../../components/icons/RemixIcon';
import IconBlock from '../../components/icons/IconBlock';

import ReceiveMail from "./receiveMail.tsx";
import ReceiveWhatsapp from "./receiveWhatsapp.tsx";



const StyledSnack = styled(NotistackSnackbarProvider)(() => ({

    /* "&.notistack-MuiContent": {
        boxShadow: "none",
        border: "1px solid #E0E2E4",
        borderRadius: 8,
    },
    '&.notistack-MuiContent-success': {
        backgroundColor: 'white',
        color: "green",
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: 'white',
        color: "red",
    },
    '&.notistack-MuiContent-default': {
        backgroundColor: 'white',
        color: "blue",
    }, */
    
}));

const SuccessIcon = () => {
    return (
        <IconBlock color="success">
            <RemixIcon className="ri-check-line"></RemixIcon>
       </IconBlock>
    );
}

const ErrorIcon = () => {
    return (
        <IconBlock color="error">
            <RemixIcon className="ri-close-line"></RemixIcon>
       </IconBlock>
    );
}

const WarningIcon = () => {
    return (
        <IconBlock color="warning">
            <RemixIcon className="ri-error-warning-line"></RemixIcon>
       </IconBlock>
    );
}

const InfoIcon = () => {
    return (
        <IconBlock color="info">
            <RemixIcon className="ri-error-warning-line"></RemixIcon>
       </IconBlock>
    );
}

const SnackbarCloseButton = ({ snackbarKey }) => {
    const { closeSnackbar } = useSnackbar();
  
    return (
      <IconButton onClick={() => closeSnackbar(snackbarKey)}>
        <RemixIcon className="ri-close-line"></RemixIcon>
      </IconButton>
    );
}

export const SnackbarProvider = ({ children }) => {

    return (
        <>
        <StyledSnack
            maxSnack={3}
            autoHideDuration={5000}
            dense
            iconVariant={{
                success: <SuccessIcon/>,
                error: <ErrorIcon/>,
                warning: <WarningIcon/>,
                info: <InfoIcon/>,
            }}
            
            Components={{
                //success: StyledMaterialDesignContent,
                receiveMail: ReceiveMail,
                receiveWhatsapp: ReceiveWhatsapp,
            }}

            action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
        >
            {children}
        </StyledSnack>
        </>
    );

};

