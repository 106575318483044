import MainMenu from './mainMenu';

const Layout = ({ children }) => {
  return (
    <>
      <div className="app">
        <MainMenu />
        <main>{children}</main>
      </div>
    </>
  )
}

export default Layout