import { createContext, useCallback, useEffect, useRef, useState } from "react";
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import useApi from "../hooks/useApi";
import { enqueueSnackbar } from "notistack";
import useAuth from "../hooks/useAuth";
import useDesktopNotification from "../hooks/useDesktopNotification";

export const LaravelEchoContext = createContext({});

export const LaravelEchoProvider = ({ children }) => {

    const { apiUrl, apiInit, csrfCookie, csrfHeader } = useApi();

    const [echo, setEcho] = useState(null);

    const { signed, user } = useAuth();

    const loadedChannels = useRef([]);

    const {makeNotification} = useDesktopNotification();

    const wsEnabled = (process.env.REACT_APP_PUSHER_HOST !== undefined && process.env.REACT_APP_PUSHER_KEY !== undefined);

    useEffect(() => {

        if(!wsEnabled) {
            console?.debug("PUSHER_HOST e/ou PUSHER_KEY não indentificado - WS desabilitado");
            return;
        }

        if (echo) {
            console?.debug(echo.connector.pusher.connection.state);
            return;
        }

        csrfCookie().then(() => {

            if (signed) {

                window.Pusher = Pusher;

                const e = new Echo({
                    broadcaster: 'pusher',
                    key: process.env.REACT_APP_PUSHER_KEY,
                    cluster: process.env.REACT_APP_PUSHER_CLASTER,
                    wsHost: process.env.REACT_APP_PUSHER_HOST,
                    wsPort: process.env.REACT_APP_PUSHER_PORT || 6001,
                    wssPort: process.env.REACT_APP_PUSHER_PORT || 6001,
                    forceTLS: false,
                    disableStats: true,
                    authorizer: (channel, options) => {
                        return {
                            authorize: (socketId, callback) => {
                                fetch(apiUrl('broadcasting/auth'), apiInit({
                                    ...csrfHeader(),
                                    method: "POST",
                                    body: JSON.stringify({
                                        socket_id: socketId,
                                        channel_name: channel.name,
                                    })
                                }))
                                    .then(response => response.json())
                                    .then(data => {
                                        console?.debug(data);
                                        callback(false, data);
                                    })
                                    .catch(error => {
                                        callback(true, error);
                                    });
                            }
                        };
                    }
                });

                setEcho(e);

            }

        });

    }, [echo, setEcho, signed, wsEnabled, apiUrl, apiInit, csrfHeader, csrfCookie]);


    if (!signed && echo) {

        console?.debug("disconnect");

        echo.connector.pusher.disconnect();
        setEcho(null);
        loadedChannels.current = [];

    }

    const requestPermission = useCallback(() => {

        if (!("Notification" in window)) {
            console?.debug("Browser does not support desktop notification");
        } else if (window.location.protocol === 'http:') {
            console?.debug("http protocol does not support desktop notification");
        } else {
    
            if (Notification.permission === "denied") {
                //faz nada
            } else if (Notification.permission !== "granted") {
                setTimeout(() => {
                    Notification.requestPermission();
                }, 3000);
            }
    
        }

    },[]);

    const showNotification = useCallback((title, body, tag, data) => {

        makeNotification(title, body, tag, data);

    },[makeNotification]);

    const createPrivateChannel = useCallback((channel) => {

        if (wsEnabled && signed && echo) {

            const channelName = `${channel}.${user.id}`;

            console?.debug('loadedChannels', loadedChannels?.current);
            
            const findedChannel = loadedChannels.current.find((element) => element.name === `private-${channelName}`);

            if(findedChannel) {

                console.log('encontrado canal', findedChannel);
                return findedChannel;
                //return null;

            } else {

                console?.debug("criado canal:", channelName)

                const channel = echo.private(channelName);

                loadedChannels.current.push(channel);

                return channel;

            }

        } else {
            
            console?.debug("createPrivateChannel: no user logged");
        }

        return null;

    }, [signed, wsEnabled, user, echo, loadedChannels]);

    useEffect(() => {

        if(!wsEnabled) {
            return;
        }

        requestPermission();
        
        const c = createPrivateChannel('notification');

        c?.stopListening('.notification.notistack')
        ?.stopListening('.notification.desktop');

        c?.listen('.notification.notistack', (e) => {
            console?.debug(e);
            enqueueSnackbar(e?.message, { variant: e?.variant });
        }).listen('.notification.desktop', (e) => {
            console?.debug(e);
            showNotification(e?.title, e?.body, e?.tag, e?.data);
        }).error((error) => {
            console?.debug('ERROR: ', error)
        });

    },[requestPermission, createPrivateChannel, showNotification, wsEnabled]);


    return (
        <LaravelEchoContext.Provider value={{ createPrivateChannel }}>
            {children}
        </LaravelEchoContext.Provider>
    );
};