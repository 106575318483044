import React from 'react'

const ChannelMailIcon = (props) => {

    const className = `svg-theme-color ${props.className}`; 

    return (
        <>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} {...props}>
                <g clipPath="url(#clip0_1578_9533)">
                    <rect x="2" y="2" width="16" height="16" rx="4" fill="#CFABE9" />
                    <g clipPath="url(#clip1_1578_9533)">
                        <path d="M8 11L2 17" stroke="#F6F8FA" />
                        <path d="M12 11L18 17" stroke="#F6F8FA" />
                        <path d="M-2 -0.5H-3.20711L-2.35355 0.353553L9.64645 12.3536L10 12.7071L10.3536 12.3536L22.3536 0.353553L23.2071 -0.5H22H-2Z" fill="#963CC8" stroke="#F6F8FA" />
                    </g>
                </g>
                <rect x="1" y="1" width="18" height="18" rx="5" stroke="white" strokeWidth="2" />
                <defs>
                    <clipPath id="clip0_1578_9533">
                        <rect x="2" y="2" width="16" height="16" rx="4" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_1578_9533">
                        <rect width="16" height="16" fill="white" transform="translate(2 2)" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}

export default ChannelMailIcon;