import { createContext } from "react";
import merge from "lodash/merge";
import { getCookie } from "../utils/Cookies";
import { getApiBaseUrl } from "../utils/whiteLabelUrl";

export const ApiContext = createContext({});

export const ApiProvider = ({ children }) => {

	const baseUrl = () => {

		let apiBaseUrl = process.env.REACT_APP_API_BASE_URL || null;

		let baseUrl = apiBaseUrl ? apiBaseUrl : getApiBaseUrl();

		return baseUrl;

	}

	const apiPath = () => {

		let apiVersion = process.env.REACT_APP_API_PATH_URL;
		return apiVersion;

	}

	/**
	 * Retorna o endpoint completo da API. (URL base + endpoint) 
	 * @param {string} endpoint
	 */
	const apiUrl = (endpoint) => {

		let apiUrl = baseUrl() + '/' + apiPath() + '/' + endpoint;
		return apiUrl;

	}

	const csrfCookie = async () => {

		await fetch(apiUrl('csrf-cookie'),
			{
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);

	}

	const csrfHeader = () => {

		return {
			headers: {
				'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
			}
		};

	}

	/**
	 * Retorna as configurações de request básicas para chamadas de API. Os valores padrão são substituidos ou acrecentados pelo parÂmetro init 
	 * @param {Object} init
	 */
	const apiInit = (init = {}) => {

		let defaultInit = {
			headers: {
				...apiHeaders(),
			},
			credentials: "include",
		};

		return merge(defaultInit, init);
	}

	const apiHeaders = () => {
		return {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
		}
	}

	return (
		<ApiContext.Provider value={{ baseUrl, apiUrl, apiInit, apiHeaders, csrfCookie, csrfHeader }}>
			{children}
		</ApiContext.Provider>
	);
};