import React from 'react'

/**
 * 
 * @param {string} color - green, blue, orange, success, error, info, default, warning
 * @param {string} size - normal, small
 */
const IconBlock = ({children, color, size}) => {

    let c = color || "default"; 
    let s = !size || size === 'normal' ? "" : `icon-block-${size}`;
   
    return (
        <div className={`icon-block ${c} ${s}`}>
            {children}
        </div>
    )
}

export default IconBlock;