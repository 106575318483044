import { createContext, useEffect, useState } from "react";
import useApi from "../hooks/useApi";

export const PipelineContext = createContext({});

export const PipelineProvider = ({ children }) => {

  const { apiUrl, apiInit } = useApi();

  const [pipelineId, setPipelineId] = useState(null);
  const [pipelineStepId, setPipelineStepId] = useState(null);

  useEffect(() => {

    let selectedPipelineId = localStorage.getItem("pipeline_id");
    let selectedPipelineStepId = localStorage.getItem("pipeline_step_id");

    if (selectedPipelineId > 0) {
      selectedPipelineId = parseInt(selectedPipelineId);
      setPipelineId(selectedPipelineId);
    }

    if (selectedPipelineStepId > 0) {
      selectedPipelineStepId = parseInt(selectedPipelineStepId);
      setPipelineStepId(selectedPipelineStepId);
    }

  }, []);

  const loadPipelineSteps = async () => {

    let result = null;
    let data = null;

    if(window._pipeline_steps === undefined || window._pipeline_steps === null) {

      result = await fetch(apiUrl('pipeline-steps?' + new URLSearchParams({ ...pipelineParams() })), apiInit());

      data = await result.json();

      window._pipeline_steps = data.data;

    } 
    
    return window._pipeline_steps || [];

  }

  const storagePipelineId = (id) => {
    localStorage.setItem("pipeline_id", id);
    setPipelineId(id);

  };

  const cleanupPipelineId = () => {
    localStorage.removeItem("pipeline_id");
    setPipelineId(null);
  };

  const storagePipelineStepId = (id) => {
    localStorage.setItem("pipeline_step_id", id);
    setPipelineStepId(id);

  };

  const cleanupPipelineStepId = () => {
    localStorage.removeItem("pipeline_step_id");
    setPipelineStepId(null);
  };

  const pipelineParams = (includedParams = ['pipelineId', 'pipelineStepId']) => {

    let result = {};

    if(includedParams.includes('pipelineId') && pipelineId) {
      result.pipeline_id = pipelineId;
    }

    if(includedParams.includes('pipelineStepId') && pipelineStepId) {
      result.pipeline_step_id = pipelineStepId;
    }

    return result;

  };

  return (
    <PipelineContext.Provider value={{ pipelineId, pipelineStepId, storagePipelineId, cleanupPipelineId, storagePipelineStepId, cleanupPipelineStepId, pipelineParams, loadPipelineSteps }}>
      {children}
    </PipelineContext.Provider>
  );
};